



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Location } from 'vue-router/types/router';

import TheArrowHorizIcon from '@/components/icons/TheArrowHorizIcon.vue';
import TheFilterIcon from '@/components/icons/TheFilterIcon.vue';
import TheGeoMarkerIcon from '@/components/icons/TheGeoMarkerIcon.vue';

@Component({
  components: { TheArrowHorizIcon, TheFilterIcon, TheGeoMarkerIcon },
})
export default class AppHeaderMobile extends Vue {
  @Prop() geoMarkerRoute: Location;
  @Prop({ default: null }) goBackRoute: Location | null;
  @Prop({ default: true, type: Boolean }) showAsFixed: boolean;
  @Prop({ default: false, type: Boolean }) showFilterBtn: boolean;
  @Prop() title: string;

  isFixed = false;

  @Watch('showAsFixed')
  watchShowAsFixed(showAsFixed: boolean) {
    this.setIsFixed(showAsFixed);
  }

  created() {
    window.addEventListener('scroll', this.handleScroll);
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  goBack() {
    if (this.goBackRoute) {
      this.$router.push(this.goBackRoute);
    } else {
      this.$router.go(-1);
    }
  }

  handleScroll() {
    this.setIsFixed(this.showAsFixed);
  }

  setIsFixed(showAsFixed: boolean) {
    this.isFixed = showAsFixed ? window.scrollY > 0 : false;
  }

  toggleAppDataFilter() {
    this.$store.dispatch('toggleAppDataFilter', true);
  }
}
